import React, { useState } from "react";
import { Stepper } from "react-form-stepper";
import {
  AddressDetailsForm,
  PersonalDetailsForm,
  BankDetailsForm,
  WorkDetailsForm,
} from "./EmployeeForm";
import useRead from "../../hooks/useRead";
import Spinner from "../../components/loader/Spinner";
import EmployeeProvider from "../../providers/EmployeeProvider";
import { Toaster } from "react-hot-toast";
import EditEmployeeProvider from "../../providers/EditEmployeeProvider";
function AddEmployeeDetails() {
  const [step, setStep] = useState(0);
  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };
  const handlePrevStep = () => {
    setStep((prev) => prev - 1);
  };
  const { list: employmentList } = useRead({
    url: "employment_listing",
    initialData: {
      limit: 50,
      search_query: "",
    },
  });
  const { list: shiftList } = useRead({
    url: "shift_listing",
    method:'GET',
    initialData: {
      limit: 50,
      search_query: "",
    },
  });

  const { list: departmentListing } = useRead({
    url: "department_listing",
    method:'GET',
    initialData: {
      limit: 50,
      search_query: "",
    },
  });

  return (
    <EditEmployeeProvider initialValue={{}}>
      <Toaster/>
      {(employmentList.isLoading || shiftList.isLoading || departmentListing.isLoading) && <Spinner />}
      <div className="px-5">
        <Stepper
          steps={[
            { label: "Personal Details" },
            { label: "Address Details" },
            { label: "Work Details" },
            { label: "Bank Details" },
          ]}
          activeStep={step}
        />
        <div className="my-10">
          {step === 0 && (
            <>
              <PersonalDetailsForm onNextStep={handleNextStep} />
            </>
          )}
          {step === 1 && (
            <AddressDetailsForm
              onNextStep={handleNextStep}
              onPreviousStep={handlePrevStep}
            />
          )}
          {step === 2 && (
            <WorkDetailsForm
              onNextStep={handleNextStep}
              onPreviousStep={handlePrevStep}
              employmentList={employmentList.data?.data || []}
              shiftList={shiftList.data?.data || []}
              departmentListing={departmentListing?.data?.data || []}
            />
          )}
          {step === 3 && (
            <BankDetailsForm
              onNextStep={handleNextStep}
              onPreviousStep={handlePrevStep}
            />
          )}
        </div>
      </div>
    </EditEmployeeProvider>
  );
}

export default AddEmployeeDetails;
