import React from "react";
import { Link } from "react-router-dom";
import useGet from "../../hooks/read/useGet";
import toast from "react-hot-toast";

const ListWFHEmployee = () => {
  const {
    list: applicationList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "wfhApplicationList",
    onSuccess: () => {},
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  function getDaysBetweenDates(fromDateTimestamp, toDateTimestamp) {
    // Convert the timestamps from seconds to milliseconds
    const fromDate = new Date(fromDateTimestamp * 1000);
    const toDate = new Date(toDateTimestamp * 1000);

    // Calculate the difference in milliseconds
    const timeDifference = toDate.getTime() - fromDate.getTime();

    // Convert the time difference to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Return the number of days, rounded to the nearest whole number
    console.log(Math.round(daysDifference));

    return Math.round(daysDifference);
  }
  return (
    <div className="px-5">
      <div className=" my-5 text-right">
        <Link to="/wfh/apply">
          <button className=" px-4 py-3 rounded-md bg-[color:var(--color1)] text-white">
            Apply For WFH
          </button>
        </Link>
      </div>
      <table class="table-auto w-full">
        <thead>
          <tr class=" bg-[color:var(--color1)] text-center">
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4 border-l border-transparent">
              Sr.No
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Applied Date
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Number of days
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Status
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Reason
            </th>
          </tr>
        </thead>
        <tbody>
          {applicationList?.data?.data?.map((item, index) => (
            <tr key={index}>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                {index + 1}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                {new Date(item?.from_date * 1000).toLocaleDateString()}
                <span> - </span>
                {new Date(item?.to_date * 1000).toLocaleDateString()}
              </td>
              <td className=" text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                {getDaysBetweenDates(item?.from_date, item?.to_date)}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                {item.status === "1" ? (
                  <span className="text-green-600 bg-green-100 px-2 text-xs py-2 font-semibold rounded-md">
                    Approved
                  </span>
                ) : item.status === "2" ? (
                  <span className="text-yellow-600 bg-yellow-100 px-2 text-xs py-2 font-semibold rounded-md">
                    Pending
                  </span>
                ) : (
                  <span className="text-red-600 bg-red-100 px-2 text-xs py-2 font-semibold rounded-md">
                    Rejected
                  </span>
                )}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                {item.reason}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListWFHEmployee;
