import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { PunchingButton } from '../../components/punchinButton';
import { Button } from '../../components/ui';
import usePut from '../../hooks/put/usePut';
import toast, { Toaster } from 'react-hot-toast';

const mapStyles = {
    height: "30vh",
    width: "100%"
};

const PunchIn = () => {
    const navigate = useNavigate()
    const [isLocationDenined, setIsLocationDenined] = useState(0)
    const [uploadedImage, setUploadedImage] = useState({})
    const [coordinates, setCoordinates] = useState({
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
    })
    const [zoom, setZoom] = useState(16)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCYBAbuZ0pN5BkdsJDJ__-3ysfkl5QjdfM" // HRMS KEY
    })
    const { handleAuth } = usePut({
        url: 'user_check_in',
        onSuccess: (res) => {
            toast.success('Attendance added successfully')
            setTimeout(() => {
                navigate('/')
            }, [2000])
        },
        onError: (err) => {
            const { status, data } = err?.response
            if (status >= 400 || status <= 499) {
                toast.error(data.message)
            } else {
                toast.error('Internal server error')
            }
        }
    })
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(coordinates.center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { coords } = position
                const data = {
                    lat: coords?.latitude,
                    lng: coords?.longitude
                }
                setZoom(16)
                setCoordinates({ center: data })
            },
            (error) => {
                if (error.PERMISSION_DENIED) {
                    setIsLocationDenined(error.PERMISSION_DENIED)
                } else {

                }
                // console.log(error.PERMISSION_DENIED);
            }
        );
    }, [])

    const markerOnLoad = (marker) => {
        // console.log('marker: ', marker)
    }

    const handleSubmitPunch = () => {
        if(uploadedImage?.image_id){
            let data = {
                lat: coordinates?.center?.lat,
                long: coordinates?.center?.lng,
                image_id: uploadedImage?.image_id
            }
            // console.log(data);
            handleAuth.mutate(data)
        }else{
            toast.error('Please add picture of yourself')
        }
    }

    const requestLocationAccess = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { coords } = position
                const data = {
                    lat: coords?.latitude,
                    lng: coords?.longitude
                }
                setZoom(16)
                setCoordinates({ center: data })
            },
            (error) => {
            }
        );
    };
    return (
        <div className='px-5 '>
            <Toaster />
            {/* <div className='flex items-center justify-end'>
                <Link to={`/attendance-punch-in/logs`} className=' text-blue-600 font-semibold hover:underline'>
                    View Punch Logs
                </Link>
            </div> */}
            <div className='my-4 flex flex-col  justify-between min-h-[calc(100vh_-_8rem)]'>
                <div>
                    {isLocationDenined ?
                        <>
                            <p className=' text-center text-red-700 mt-4 font-semibold'>Please provide access to your location.</p>
                            <p className=' text-xs text-center text-gray-600'>
                                Some browser extensions or settings may block popup windows, including permission prompts. Check your browser settings or disable any popup blockers temporarily to see if it resolves the issue.
                            </p>
                        </> : null
                    }

                    {!isLocationDenined &&
                        <>
                            <div style={{ height: '30vh', width: '100%' }}>
                                {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        center={coordinates.center}
                                        zoom={zoom}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                    >
                                        <Marker visible={true} position={coordinates.center} onLoad={markerOnLoad} />
                                    </GoogleMap>
                                ) : <></>}
                            </div>
                            <PunchingButton setUploadedImage={setUploadedImage} />
                        </>
                    }
                </div>
                {!isLocationDenined ?
                    <Button disabled={isLocationDenined} onClick={handleSubmitPunch}>
                        Submit
                    </Button> : null}
                {isLocationDenined ?
                    <Button onClick={requestLocationAccess}>
                        Grant Location Access
                    </Button> : null}
            </div>
        </div>
    )
}

export { PunchIn }