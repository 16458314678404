import React from 'react'
import Input from '../../components/form/Input'

const PreviousLeaves = ({
    employeeType,
    type,
    data
}) => {
    console.log(data);
    const { applicableLeaves,user_type } = data
    return (
        <div className=' space-y-5'>
            {(user_type === 'consultant' )
                ? <>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Other Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.annual_ol}
                                </p>
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.pending_ol_leave}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
                : user_type === 'employee' ? <>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Privilege Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.annual_pl}
                                </p>
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.pending_pl_leave}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Casual Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.annual_cl}
                                </p>
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.pending_cl_leave}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Sick Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.annual_sl}
                                </p>
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <p className='border rounded px-3 py-2 h-10'>
                                    {applicableLeaves?.pending_sl_leave}
                                </p>
                            </div>
                        </div>
                    </div>
                </> :
                    null
            }
        </div>
    )
}

export default PreviousLeaves