import React from 'react'
import { COMPANY_NAME } from '../../config';
import LoginWithGoogle from './LoginWithGoogle';
import { Toaster } from 'react-hot-toast';
import bgAbstract from '../../assets/loginPattern.svg'
function Login() {
    return (
        <>
            <Toaster position="top-right"/>
            <div className=' h-screen bg-bodyBackground flex flex-col lg:flex-row overflow-hidden fixed w-full'>
                <div className='w-full lg:w-[60%] h-[60%] lg:h-full loginPage-bgImage relative l' style={{
                    backgroundImage: `url(${bgAbstract})`
                }}>
                    <div className=' p-10 lg:p-16 h-full flex flex-col justify-between'>
                        <h1 className=' text-white text-2xl lg:text-5xl font-bold drop-shadow-md tracking-wider font-roboto'>{COMPANY_NAME} HRMS</h1>
                        <div>
                            <div className=' mt-10 lg:mt-0 mb-6 lg:mb-24'>
                            {/* Empower Your Workforce. */}
                                <h3 className=' drop-shadow-md text-white text-base lg:text-4xl mb-4 font-semibold'>Streamline HR Operations</h3>
                                <p className=' text-white drop-shadow-md text-base lg:text-lg'>
                                    Unlock the Potential of Your Workforce. Discover a Dynamic Hub Where Data Flourishes. Our HRMS Platform is the Gateway to a More Efficient, Connected, and Innovative Workplace.
                                </p>
                            </div>
                            <div className=' space-x-6'>
                                <a href="http://" target="_blank" rel="noopener noreferrer" className=' text-sm lg:text-base text-white drop-shadow-md hover:underline'>
                                    Privacy Policy
                                </a>
                                <span className='text-white drop-shadow-md text-sm lg:text-base'>© 2024 {COMPANY_NAME}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full lg:w-[40%] h-[40%] lg:h-full flex flex-col items-center justify-center'>
                    <h1 className=' text-primary font-semibold text-3xl mb-10'>
                        Welcome to {COMPANY_NAME} HRMS
                    </h1>
                    <LoginWithGoogle/>
                </div>
            </div>
        </>
    )
}

export default Login