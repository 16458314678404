import React, { forwardRef } from 'react'
import Input from '../../components/form/Input'

const LeavesCalculation = forwardRef(({ control, employeeType, onChangeText, type }, ref) => {
    return (
        <div className=' space-y-5'>
            {(employeeType === 'consultant' && type === 'On Site')
                ? <>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Other Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <Input disabled name={'leaves.ol.annual'} placeholder={'Annual Leave'} onChangeText={(value) => onChangeText(value, 'leaves.ol.available')} control={control}
                                    rules={{
                                        required: 'Annual Leave required',
                                    }}
                                />
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <Input name={'leaves.ol.available'} placeholder={'Available Leave'} control={control}
                                    rules={{
                                        required: 'Available Leave required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
                : employeeType === 'employee' ? <>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Privilege Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <Input disabled name={'leaves.pl.annual'} placeholder={'Annual Leave'} onChangeText={(value) => onChangeText(value, 'leaves.pl.available')} control={control}
                                    rules={{
                                        required: 'Annual Leave required',
                                    }}
                                />
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <Input name={'leaves.pl.available'} placeholder={'Available Leave'} control={control}
                                    rules={{
                                        required: 'Available Leave required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Casual Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <Input disabled name={'leaves.cl.annual'} placeholder={'Annual Leave'} onChangeText={(value) => onChangeText(value, 'leaves.cl.available')} control={control}
                                    rules={{
                                        required: 'Annual Leave required',
                                    }}
                                />
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <Input name={'leaves.cl.available'} placeholder={'Available Leave'} control={control}
                                    rules={{
                                        required: 'Available Leave required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="pl_leave" className='label'>Sick Leave:</label>
                        <div className='grid grid-cols-3 gap-4'>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                                <Input disabled name={'leaves.sl.annual'} placeholder={'Annual Leave'} onChangeText={(value) => onChangeText(value, 'leaves.sl.available')} control={control}
                                    rules={{
                                        required: 'Annual Leave required',
                                    }}
                                />
                            </div>
                            <div className='mt-4'>
                                <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                                <Input name={'leaves.sl.available'} placeholder={'Available Leave'} control={control}
                                    rules={{
                                        required: 'Available Leave required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </> :
                    null
            }
        </div>
    )
})

export default LeavesCalculation