import Input from "../../components/form/Input";
import useWFH from "../../hooks/WFH/useWFH";

const WFHApplicationForm = () => {
  const { applyWFHForm, handleApplyWFH } = useWFH();
  return (
    <div className="px-5">
      <form action="">
        <div>
          <label>
            <input
              type="checkbox"
              {...applyWFHForm.register("isFullDay")}
              className="mr-2"
            />
            is Full Day?
          </label>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-3 gap-5 my-6">
          <div>
            <label htmlFor="FromDate">From Date</label>
            <Input
              control={applyWFHForm.control}
              label={"From Date"}
              name={"from_date"}
              type="date"
              rules={{
                required: "From Date is required",
              }}
            />
          </div>
          <div>
            <label htmlFor="ToDate">To Date</label>
            <Input
              control={applyWFHForm.control}
              label={"To Date"}
              name={"to_date"}
              type="date"
              rules={{
                required: "To Date is required",
              }}
            />
          </div>
        </div>
        <div className="mb-6">
          <label htmlFor="reason">Reason</label>
          <textarea
            {...applyWFHForm.register("reason", {
              required: "Reason is required", // Add the required rule here
            })}
            className="flex min-h-[10rem] w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
          ></textarea>
          {applyWFHForm.formState.errors.reason && ( // Show error message if there's a validation error
            <p className="text-red-600 text-xs">
              {applyWFHForm.formState.errors.reason.message}
            </p>
          )}
        </div>
        <div>
          <button
            type="button"
            onClick={applyWFHForm.handleSubmit(handleApplyWFH)}
            className=" px-4 py-3 rounded-md bg-[color:var(--color1)] text-white"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default WFHApplicationForm;
